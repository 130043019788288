import { useContext, useCallback } from 'react';
import styled from 'styled-components';
import TabContext from './subComponent/TabContext';

const TabTab = ({ children, name, ...props }) => {
  const { selected, onChange } = useContext(TabContext);

  const handleClick = useCallback(() => {
    if (selected !== name) {
      onChange(name);
    }
  }, [name, selected, onChange]);

  return (
    <Tab role='tab' onClick={handleClick} active={selected === name} {...props}>
      {children}
    </Tab>
  );
};

const Tab = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  flex: 1;
  background: var(--color-white);
  border: none;
  cursor: pointer;
  color: var(--font-on-background);

  ${({ active }) =>
    active &&
    `
    &::after {
      content: '';
      height: 4px;
      width: 48px;
      position: absolute;
      bottom: 0;
      background: var(--color-primary);
      border-radius: 4px;
    }
  `}
`;

export default TabTab;
