import { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthenticateContext from '../provider/context/authenticate.context';
import useQuery from '../utils/useQuery';
import Home from './home/Home';
import Login from './login/Login';
import Register from './register/Register';
import ResetPassword from './resetPassword/ResetPassword';
import Coupon from './coupon/Coupon';
import Profile from './profile/Profile';
import ChangePassword from './changePassword/ChangePassword';

const Router = () => {
  const serialNumber = useQuery().get('sn') || '';
  const { isGetting, authToken } = useContext(AuthenticateContext);

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Register />} />
      <Route path='/reset-password' element={<ResetPassword />} />

      {!isGetting && (
        <>
          <Route
            path='/coupon'
            element={
              authToken ? (
                <Coupon />
              ) : (
                <Navigate to={`/login?sn=${serialNumber}`} replace={true} />
              )
            }
          />
          <Route
            path='/profile'
            element={
              authToken ? (
                <Profile />
              ) : (
                <Navigate to={`/login?sn=${serialNumber}`} replace={true} />
              )
            }
          />
          <Route
            path='/change-password'
            element={
              authToken ? (
                <ChangePassword />
              ) : (
                <Navigate to={`/login?sn=${serialNumber}`} replace={true} />
              )
            }
          />
        </>
      )}
    </Routes>
  );
};

export default Router;
