import { forwardRef } from 'react';
import styled from 'styled-components';
import { GoSearch } from 'react-icons/go';

const SearchBox = forwardRef((props, ref) => {
  const handleClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Wrapper onClick={handleClick}>
      <GoSearch />
      <SearchInput type='text' ref={ref} {...props} />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  position: relative;
  padding: 4px;
  margin: 0 !important;

  > svg {
    color: gray;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;
Wrapper.displayName = 'Wrapper';

const SearchInput = styled.input`
  background: var(--color-white);
  color: var(--color-black);
  border-radius: var(--border-radius-l);
  border: var(--border-width) solid var(--border-color);
  outline: none;
  width: 100%;
  padding: 10px 4px 10px 20px;
  max-height: 36px;

  &:disabled {
    cursor: not-allowed;
  }
`;
SearchInput.displayName = 'SearchInput';

export default SearchBox;
