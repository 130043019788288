import ImageFluid from '../ImageFluid';
import wavy from '../../assets/images/wavy.svg';

const Wavy = () => {
  return (
    <figure>
      <ImageFluid src={wavy} alt='wavy effect' />
    </figure>
  );
};

export default Wavy;
