import { ThemeProvider } from 'styled-components';
import themes from '../styles/theme';
import { useCallback, useContext } from 'react';
import { showErrorMessage } from '../module/message';
import AxiosInterceptor from '../services/api';
import AuthenticateContext from '../provider/context/authenticate.context';
import GlobalStyle from '../components/GlobalStyle';
import CustomToastContainer from '../components/toast/CustomToastContainer';
import Router from './Router';

const App = () => {
  const { authToken } = useContext(AuthenticateContext);

  const handleBeforeSend = useCallback(
    (config) => {
      // call external api no need send authenticate information
      if (authToken && !config.external) {
        config.headers['Auth-Token'] = authToken;
      }

      return config;
    },
    [authToken],
  );

  const handleDone = useCallback((response) => {
    // call external api no need check status
    if (response.config.external) {
      return response;
    }

    const { data } = response;
    switch (response.status) {
      case 200:
      case 201:
      case 202:
      case 204:
        if (data !== undefined && data.code !== 0) {
          showErrorMessage({ message: data.message });
          return null;
        }

        return response;
      default:
        showErrorMessage({ message: data.message });
    }
  }, []);

  return (
    <ThemeProvider theme={themes.cola}>
      <GlobalStyle />

      <AxiosInterceptor onBeforeSend={handleBeforeSend} onDone={handleDone} />

      <Router />

      <CustomToastContainer />
    </ThemeProvider>
  );
};
export default App;
