import styled from 'styled-components';

const Input = styled.input`
  display: block;
  width: 100%;
  height: 36px;
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 8px;
  /* fix box-shadow on ios */
  -webkit-appearance: none;
  box-shadow: 0 0 4px var(--color-primary-light);

  &:focus {
    border-color: var(--color-primary);
    outline: none;
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
    box-shadow: 0 0 4px var(--color-black);
  `}
`;

export default Input;
