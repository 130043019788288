import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import uniqueId from 'lodash.uniqueid';
import { TiArrowSortedDown } from 'react-icons/ti';
import { MdClose } from 'react-icons/md';
import useCustomPopper from '../../hooks/useCustomPopper';
import SelectedSingle from './subComponent/SelectedSingle';
import SelectedMultiple from './subComponent/SelectedMultiple';
import ListBox from './subComponent/ListBox';
import SearchBox from './subComponent/SearchBox';
import Options from './subComponent/Options';
import OptionGroup from './subComponent/OptionGroup';
import Option from './subComponent/Option';

const KEY_CODE = {
  ENTER: 13,
  ESCAPE: 27,
  UP: 38,
  DOWN: 40,
  END: 35,
  HOME: 36,
};

const Select = ({
  options,
  selected,
  onSelect,
  onDeselect,
  multiple = false,
  allowClear = false,
  allowSearch = true,
  disabled,
  placeholder = '',
  fullWidth,
  large,
  fill,
  fillReverse,
  onBlur,
}) => {
  const [id] = useState(uniqueId('select-'));
  const [display, setDisplay] = useState(false);
  const [comboboxElement, setComboboxElement] = useState(null);
  const [listBoxElement, setListBoxElement] = useState(null);
  const optionsElementRef = useRef(null);
  const searchBoxElement = useRef(null);
  const [listBoxWidth, setListBoxWidth] = useState(0);
  const [optionList, setOptionList] = useState([]);
  const [focusedOption, setFocusedOption] = useState(null);
  const [isFocusCombobox, setIsFocusCombobox] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [search, setSearch] = useState('');

  const { styles, attributes, state, update } = useCustomPopper(
    comboboxElement,
    listBoxElement,
  );

  // parse all value to string
  // for subsequently determine whether it is a selected item
  const parsedSelected = useMemo(() => {
    if (selected || selected === 0) {
      // multiple value
      if (Array.isArray(selected)) {
        return selected.map((value) => value.toString());
      }

      // single value
      return selected.toString();
    }
    return '';
  }, [selected]);

  // set focused option
  const handleFocusItem = useCallback((element) => {
    if (!optionsElementRef.current) return;

    setFocusedOption(element);

    const container = optionsElementRef.current;
    if (container.scrollHeight > container.clientHeight) {
      const scrollBottom = container.clientHeight + container.scrollTop;
      const elementBottom = element.offsetTop + element.offsetHeight;
      if (elementBottom > scrollBottom) {
        container.scrollTop = elementBottom - container.clientHeight;
      } else if (element.offsetTop < container.scrollTop) {
        container.scrollTop = element.offsetTop;
      }
    }
  }, []);

  const focusFirstItem = useCallback(() => {
    const firstItem = listBoxElement.querySelector('[role="option"]');

    if (firstItem) {
      handleFocusItem(firstItem);
    }
  }, [listBoxElement, handleFocusItem]);

  const setupFocus = useCallback(() => {
    if (!listBoxElement) return;

    const selectedItem = listBoxElement.querySelector('[aria-selected="true"]');

    if (selectedItem) {
      handleFocusItem(selectedItem);
    } else if (optionList.length > 0) {
      focusFirstItem();
    } else {
      setFocusedOption(null);
    }
  }, [listBoxElement, handleFocusItem, focusFirstItem, optionList]);

  const findPreviousItem = useCallback(
    (currentItem) => {
      const allItem = [...listBoxElement.querySelectorAll('[role="option"]')];
      const currentItemIndex = allItem.indexOf(currentItem);
      let previousItem = null;

      if (currentItemIndex !== -1 && currentItemIndex > 0) {
        previousItem = allItem[currentItemIndex - 1];
      }

      return previousItem;
    },
    [listBoxElement],
  );

  const findNextItem = useCallback(
    (currentItem) => {
      const allItem = [...listBoxElement.querySelectorAll('[role="option"]')];
      const currentItemIndex = allItem.indexOf(currentItem);
      let nextItem = null;

      if (currentItemIndex !== -1 && currentItemIndex < allItem.length - 1) {
        nextItem = allItem[currentItemIndex + 1];
      }

      return nextItem;
    },
    [listBoxElement],
  );

  const handleOnSelect = useCallback(
    (event, value) => {
      event.stopPropagation();
      if (disabled === true) return;

      onSelect(value);
      comboboxElement.focus();
      setSearch('');
      setDisplay(false);
    },
    [disabled, onSelect, comboboxElement],
  );

  const handleOnDeselect = useCallback(
    (event, value) => {
      event.stopPropagation();
      if (disabled === true) return;

      onDeselect(value);
    },
    [disabled, onDeselect],
  );

  // list all selected option
  const selectedItem = useMemo(() => {
    if (selectedOptions.length > 0) {
      if (multiple) {
        return selectedOptions.map((value) => {
          return (
            <SelectedMultiple
              content={value.text}
              key={value.id}
              onClick={(event) => handleOnDeselect(event, value.id)}
              disabled={disabled}
            />
          );
        });
      }

      return <SelectedSingle>{selectedOptions[0].text}</SelectedSingle>;
    }

    return placeholder;
  }, [placeholder, handleOnDeselect, multiple, selectedOptions, disabled]);

  const optionItem = useMemo(() => {
    if (optionList.length <= 0) return null;

    return optionList.map((option) => {
      if (option.child && option.child.length > 0) {
        return (
          <OptionGroup aria-labelledby={option.text} key={option.text}>
            <Option role='presentation' id={option.text}>
              {option.text}
            </Option>

            {option.child.map((option) => {
              return (
                <Option
                  role='option'
                  tabIndex='0'
                  id={option.id}
                  key={option.id}
                  onClick={(event) => handleOnSelect(event, option.id)}
                  focus={
                    focusedOption && focusedOption.id === option.id.toString()
                  }
                  selected={option.selected}
                  aria-selected={option.selected}
                >
                  {option.text}
                </Option>
              );
            })}
          </OptionGroup>
        );
      }

      return (
        <Option
          role='option'
          tabIndex='0'
          id={option.id}
          key={option.id}
          onClick={(event) => handleOnSelect(event, option.id)}
          focus={focusedOption && focusedOption.id === option.id.toString()}
          selected={option.selected}
          aria-selected={option.selected}
        >
          {option.text}
        </Option>
      );
    });
  }, [optionList, focusedOption, handleOnSelect]);

  // show or hide listbox
  // and set listbox width according to combobox current width
  const handleToggle = () => {
    if (disabled === true) return;

    const tmpDisplay = !display;
    if (!tmpDisplay) {
      setFocusedOption(null);
    }

    setDisplay(tmpDisplay);
    update();
  };

  // handle keyboard interaction
  const handleKeyUp = (event) => {
    const key = event.keyCode;

    switch (key) {
      case KEY_CODE.ENTER:
        if (!display) {
          handleToggle();
        } else if (focusedOption) {
          handleOnSelect(event, focusedOption.id);
        }

        break;
      case KEY_CODE.ESCAPE:
        if (display) {
          handleToggle();
        }
        break;
      case KEY_CODE.UP: {
        if (!focusedOption) {
          return;
        }

        const previousItem = findPreviousItem(focusedOption);
        if (previousItem) {
          handleFocusItem(previousItem);
        }

        break;
      }
      case KEY_CODE.DOWN: {
        if (!focusedOption) {
          return;
        }

        const nextItem = findNextItem(focusedOption);
        if (nextItem) {
          handleFocusItem(nextItem);
        }

        break;
      }
      case KEY_CODE.END: {
        event.preventDefault();
        const itemList = listBoxElement.querySelectorAll('[role="option"]');

        if (itemList.length) {
          handleFocusItem(itemList[itemList.length - 1]);
        }

        break;
      }
      case KEY_CODE.HOME:
        event.preventDefault();
        focusFirstItem();
        break;
      default:
        break;
    }
  };

  const handleKeyDown = (event) => {
    const key = event.keyCode;

    switch (key) {
      case KEY_CODE.ENTER:
      case KEY_CODE.ESCAPE:
      case KEY_CODE.UP:
      case KEY_CODE.DOWN:
      case KEY_CODE.END:
      case KEY_CODE.HOME:
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  // handle search keyword change
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleFocus = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsFocusCombobox(true);
  };

  // determine selected option(s)
  useEffect(() => {
    if (!options) return;

    const tmpSelectedOptions = [];
    options.forEach((option) => {
      if (option.child && option.child.length > 0) {
        option.child.forEach((option) => {
          if (multiple && parsedSelected.includes(option.id.toString())) {
            tmpSelectedOptions.push(option);
          } else if (parsedSelected === option.id.toString()) {
            tmpSelectedOptions.push(option);
          }
        });
      } else {
        if (multiple && parsedSelected.includes(option.id.toString())) {
          tmpSelectedOptions.push(option);
        } else if (parsedSelected === option.id.toString()) {
          tmpSelectedOptions.push(option);
        }
      }

      return false;
    });

    setSelectedOptions(tmpSelectedOptions);
  }, [options, parsedSelected, multiple]);

  useEffect(() => {
    if (update) {
      setTimeout(() => {
        update();
      }, 100);
    }
  }, [update, listBoxWidth]);

  // observe element resized
  useEffect(() => {
    if (!comboboxElement) return;

    const resizeObserverInstance = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target) {
          const borderBoxSize = entry.target.clientWidth;

          setListBoxWidth(borderBoxSize);
        }
      }
    });

    resizeObserverInstance.observe(comboboxElement);

    return () => {
      // unobserve element
      resizeObserverInstance.unobserve(comboboxElement);
    };
  }, [comboboxElement]);

  // re-setup focus when option list changed and update popper position
  useEffect(() => {
    setupFocus();
    if (update) update();
  }, [setupFocus, optionList, update]);

  // parse option list when filtered options change
  useEffect(() => {
    if (!filteredOptions) return;

    setOptionList(
      filteredOptions.map((option) => {
        if (option.child && option.child.length > 0) {
          return {
            ...option,
            child: option.child.map((option) => {
              let selected = false;
              if (multiple && parsedSelected.includes(option.id.toString())) {
                selected = true;
              } else if (parsedSelected === option.id.toString()) {
                selected = true;
              }

              return {
                ...option,
                selected,
              };
            }),
          };
        }

        let selected = false;
        if (multiple && parsedSelected.includes(option.id.toString())) {
          selected = true;
        } else if (parsedSelected === option.id.toString()) {
          selected = true;
        }

        return {
          ...option,
          selected,
        };
      }),
    );
  }, [filteredOptions, multiple, parsedSelected]);

  // filter options on search keyword changed
  useEffect(() => {
    const tmpFilteredOptions = [];

    options.forEach((option) => {
      if (option.child && option.child.length > 0) {
        const tmpFilteredChildOptions = option.child.filter(
          (option) =>
            option.text.toLowerCase().indexOf(search.toLowerCase()) !== -1,
        );
        if (tmpFilteredChildOptions.length > 0) {
          tmpFilteredOptions.push({
            ...option,
            child: tmpFilteredChildOptions,
          });
        }
      } else if (
        option.text.toLowerCase().indexOf(search.toLowerCase()) !== -1
      ) {
        tmpFilteredOptions.push(option);
      }
    });

    setFilteredOptions(tmpFilteredOptions);
  }, [search, options]);

  useEffect(() => {
    if (display) {
      setupFocus();

      if (allowSearch) {
        searchBoxElement.current.focus();
      }
    }
  }, [setupFocus, display, allowSearch]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setDisplay(false);
        if (onBlur) onBlur();
        setIsFocusCombobox(false);
      }}
      disabled={!isFocusCombobox}
    >
      <Combobox
        role='combobox'
        tabIndex='0'
        aria-haspopup='listbox'
        aria-expanded={display}
        aria-owns={id}
        ref={setComboboxElement}
        onClick={handleToggle}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
        $display={display}
        disabled={disabled}
        fullWidth={fullWidth}
        large={large}
        $fill={fill}
        fillReverse={fillReverse}
        onFocus={handleFocus}
      >
        {selectedItem}

        {selectedOptions.length > 0 && !multiple && !disabled && allowClear && (
          <CloseButton
            onClick={() => {
              onSelect('');
            }}
          />
        )}
        <ExpandArrow aria-haspopup='listbox' aria-expanded={display} />
      </Combobox>
      <ListBox
        role='listbox'
        tabIndex='0'
        id={id}
        ref={setListBoxElement}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
        width={listBoxWidth}
        $display={display}
        style={styles.popper}
        {...attributes.popper}
        placement={state && state.placement}
      >
        {allowSearch && (
          <SearchBox
            ref={searchBoxElement}
            placeholder='Search'
            onChange={handleSearchChange}
            value={search}
          />
        )}

        <Options ref={optionsElementRef} allowSearch={allowSearch}>
          {optionItem || <Message>No results found</Message>}
        </Options>
      </ListBox>
    </OutsideClickHandler>
  );
};

const Combobox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 36px;
  cursor: pointer;
  position: relative;
  background: transparent;
  color: var(--color-white);
  font-size: var(--font-body1);
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 0 calc(4px * 2 + 8px) 0 4px;
  box-shadow: 0 0 4px var(--color-primary-light);

  &:focus {
    ${({ disabled }) =>
      !disabled &&
      `
        outline: none;
      `};
  }

  ${({ $display }) =>
    $display &&
    `
      border-color: var(--color-primary);
      outline: none;
    `}

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      opacity: 0.5;
      border-color: var(--color-black);
      box-shadow:none;
    `}

  ${({ fullWidth }) => (fullWidth ? 'width: 100%' : 'max-width: 320px')};

  ${({ large }) =>
    large &&
    `
      height: 40px;
      padding: 12px calc(var(--spacing-s) * 2 + 8px) 12px var(--spacing-s);
    `}

  ${({ $fill }) =>
    $fill &&
    `
      background: var(--color-background2);
      border-color: transparent;
    `}

${({ fillReverse }) =>
    fillReverse &&
    `
      background: var(--color-background1);
      border-color: transparent;
    `}
`;
Combobox.displayName = 'Combobox';

const CloseButton = styled(MdClose)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-primary);
  font-size: var(--font-body1);
  right: var(--spacing-l);
`;
CloseButton.displayName = 'CloseButton';

const ExpandArrow = styled(TiArrowSortedDown)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-primary);
  font-size: var(--font-body1);
  right: 4px;
`;
ExpandArrow.displayName = 'ExpandArrow';

const Message = styled.p`
  margin: 0;
  padding: 12px var(--spacing-s);
`;
Message.displayName = 'Message';

export default Select;
