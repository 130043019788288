import { useState } from 'react';
import { Link } from 'react-router-dom';
import { redeemCode } from '../../module/api';
import { showSuccessMessage, showErrorMessage } from '../../module/message';
import useQuery from '../../utils/useQuery';
import Layout from '../../components/layout/Layout';
import Heading1 from '../../components/Heading1';
import FormItem from '../../components/FormItem';
import Label from '../../components/Label';
import Input from '../../components/Input';
import Button from '../../components/Button';

const Home = () => {
  const serialNumber = useQuery().get('sn') || '';
  const [code, setCode] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!serialNumber.trim()) {
      showErrorMessage({ message: 'Serial number can not be empty' });
      return false;
    }

    if (!code.trim()) {
      showErrorMessage({ message: 'Code can not be empty' });
      return false;
    }

    redeemCode({ serialNumber, code })
      .then((response) => {
        if (response) {
          showSuccessMessage({
            message:
              'Successful redeem. Please proceed follow-up operation on vending machine.',
          });
        }
      })
      .catch((error) => {
        showErrorMessage({ message: error.message });
      });
  };

  return (
    <Layout>
      <Heading1 className='margin-top-xl margin-bottom-xxl'>
        Redeem Offer
      </Heading1>

      <form onSubmit={handleSubmit}>
        <FormItem>
          <Label htmlFor='promoCode'>Enter the promo code</Label>
          <Input
            type='text'
            id='promoCode'
            value={code}
            onChange={(event) => {
              setCode(event.target.value);
            }}
            autoFocus
          />
        </FormItem>

        <Button type='submit' className='margin-top-xxl'>
          Redeem
        </Button>
      </form>

      <section
        style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '60px' }}
      >
        <p style={{ marginBottom: '4px' }}>Don't have a promo code?</p>
        <Link
          to={`/login?sn=${serialNumber}`}
          style={{ color: 'var(--color-primary)', textDecoration: 'underline' }}
        >
          Login to get some coupons!
        </Link>
      </section>
    </Layout>
  );
};

export default Home;
