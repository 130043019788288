import { toast } from 'react-toastify';

const showSuccessMessage = ({ message }) => {
  toast(message, {
    hideProgressBar: true,
    position: toast.POSITION.TOP_CENTER,
  });
};

const showErrorMessage = ({ message }) => {
  toast(message, {
    hideProgressBar: true,
    position: toast.POSITION.TOP_CENTER,
  });
};

export { showSuccessMessage, showErrorMessage };
