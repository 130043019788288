import styled from 'styled-components';
import { Link } from 'react-router-dom';

const DropdownLinkOption = styled(Link)`
  display: flex;
  padding: 12px 16px;
  color: #ffffff;

  &:hover {
    background: gray;
  }

  > svg {
    margin-right: 16px;
  }
`;

export default DropdownLinkOption;
