import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import DropdownContext from './subComponent/DropdownContext';

const DropdownContent = ({ children, onShow, onClose }) => {
  const { display, setContentElement, styles, attributes, update } =
    useContext(DropdownContext);
  const [isToggle, setIsToggle] = useState(false);

  useEffect(() => {
    if (isToggle) {
      if (display && onShow) {
        onShow();
      }

      if (!display && onClose) {
        onClose();
      }

      setIsToggle(false);
    }
  }, [isToggle, display, onShow, onClose]);

  useEffect(() => {
    setIsToggle(true);
  }, [display]);

  // rerender時不會更新位置，所以補上children讓rerender時可以更新位置
  useEffect(() => {
    if (update) {
      update();
    }
  }, [update, children]);
  return (
    <Wrapper
      ref={setContentElement}
      $display={display}
      style={styles.popper}
      {...attributes.popper}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  visibility: ${({ $display }) => ($display === true ? 'visible' : 'hidden')};
  min-width: 160px;
  max-width: 320px;
  border: 1px solid #5b5f6f;
  color: #ffffff;
  background: #707070;
  box-shadow: 0px 0px 8px rgba(91, 95, 111, 0.5);
  border-radius: 1rem;
  padding: 16px 0;
  z-index: 20;
  margin: 0 !important;
`;

export default DropdownContent;
