import styled from 'styled-components';

const SelectedSingle = styled.div`
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 !important;
`;
SelectedSingle.displayName = 'SelectedSingle';

export default SelectedSingle;
