import styled from 'styled-components';

const ListBox = styled.div`
  visibility: ${({ $display }) => ($display ? 'visible' : 'hidden')};
  background: var(--color-white);
  color: var(--color-white);
  border: 1px solid var(--color-primary-light);
  border-radius: 8px;
  box-shadow: 0 0 4px var(--color-primary-light);
  width: ${({ width }) => width}px;
  z-index: 31;
`;
ListBox.displayName = 'ListBox';

export default ListBox;
