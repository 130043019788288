import styled from 'styled-components';

const DropdownButtonOption = styled.button`
  display: flex;
  padding: 12px 16px;
  background: #707070;
  color: #ffffff;
  width: 100%;
  border: none;
  cursor: pointer;

  &:hover {
    background: gray;
  }

  > svg {
    margin-right: 16px;
  }
`;

export default DropdownButtonOption;
