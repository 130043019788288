import styled, { css } from 'styled-components';

const Button = styled.button.attrs(() => ({
  primary: true,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  border: 1px solid transparent;
  font-size: var(--font-body1);
  font-weight: var(--font-bold);
  line-height: var(--font-body1);
  border-radius: 8px;
  background-color: var(--color-primary);
  color: var(--font-on-primary);

  ${({ secondary }) =>
    secondary &&
    css`
      background: var(--color-white);
      color: var(--color-primary);
      border-color: var(--color-primary);
    `}
`;

export default Button;
