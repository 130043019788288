import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0 auto;
    width: 100vw;
    max-width: 576px;
    height: 100vh;
    max-height: 750px;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

    --color-white: ${({ theme }) => theme.colorWhite};
    --color-black: ${({ theme }) => theme.colorBlack};
    --color-primary: ${({ theme }) => theme.colorPrimary};
    --color-primary-light: ${({ theme }) => theme.colorPrimaryLight};

    --font-h1: ${({ theme }) => theme.fontH1};
    --font-h2: ${({ theme }) => theme.fontH2};
    --font-h3: ${({ theme }) => theme.fontH3};
    --font-body1: ${({ theme }) => theme.fontBody1};
    --font-body2: ${({ theme }) => theme.fontBody2};
    --font-caption: ${({ theme }) => theme.fontCaption};
    --font-family: ${({ theme }) => theme.fontFamily};
    --font-normal: ${({ theme }) => theme.fontWeightNormal};
    --font-bold: ${({ theme }) => theme.fontWeightBold};
    --font-on-primary: ${({ theme }) => theme.fontOnPrimary};
    --font-on-background: ${({ theme }) => theme.fontOnBackground};
  }

  #root {
    height: 100%;
  }

  .margin-top {
    margin-top: 16px;
  }

  .margin-top-l {
    margin-top: 20px;
  }

  .margin-top-xl {
    margin-top: 24px;
  }

  .margin-top-xxl {
    margin-top: 32px;
  }

  .margin-bottom {
    margin-bottom: 16px;
  }

  .margin-bottom-l {
    margin-bottom: 20px;
  }

  .margin-bottom-xl {
    margin-bottom: 24px;
  }

  .margin-bottom-xxl {
    margin-bottom: 32px;
  }

  .clearFix {
    clear: both;
  }
`;

export default GlobalStyle;
