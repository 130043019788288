import styled, { css } from 'styled-components';

const Option = styled.div`
  max-width: 100%;
  word-break: break-all;
  padding: 12px 4px;
  margin: 0 !important;
  color: #000000;
  transition: background 0.3s;

  &:hover {
    background: var(--color-primary-light);
  }

  ${({ selected, focus }) =>
    (selected || focus) &&
    css`
      background: var(--color-primary-light);
    `}
`;
Option.displayName = 'Option';

export default Option;
