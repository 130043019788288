import styled from 'styled-components';

const DropdownDivOption = styled.div`
  display: flex;
  padding: 12px 16px;
  color: #ffffff;
  background: #222143;
  width: 100%;
  border: none;
  cursor: pointer;

  &:hover {
    background: rgba(89, 126, 247, 0.2);
  }

  > svg {
    margin-right: 16px;
  }
`;

export default DropdownDivOption;
