import { callApi } from '../services/api';
import MD5 from 'crypto-js/md5';

const login = ({ phone, password }) => {
  const params = { phone, password };

  return callApi.post('/login', params);
};

const register = ({ phone, code, password }) => {
  const params = { phone, code, password };

  return callApi.post('/register', params);
};

const sendVerificationCode = ({ phone, codeType }) => {
  const params = { phone, codeType };

  return callApi.post('/phone-code', params);
};

const redeemCode = ({ serialNumber, code }) => {
  const params = { code, sn: serialNumber };

  return callApi.put('/redeem-codes', params);
};

const getCurrentUserInfo = () => {
  return callApi.get('/current-user');
};

const modifyProfiles = ({ nickname, gender, oldPassword, password }) => {
  const data = {};
  if (nickname) {
    data.nickname = nickname;
  }
  if (gender) {
    data.gender = gender;
  }
  if (oldPassword) {
    data.oldPassword = MD5(oldPassword).toString();
  }
  if (password) {
    data.password = MD5(password).toString();
  }

  return callApi.put('/profiles', data);
};

/**
 * call ipapi service to get information of ip address location
 */
const getIPAddressLocation = () => {
  return callApi.get('https://ipapi.co/json', {
    external: true,
  });
};

export {
  login,
  register,
  sendVerificationCode,
  redeemCode,
  getCurrentUserInfo,
  modifyProfiles,
  getIPAddressLocation,
};
