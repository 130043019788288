import styled from 'styled-components';

const InputWithButton = ({
  type,
  id,
  value,
  placeholder,
  onChange,
  autoFocus = false,
  onClick,
  text,
}) => {
  return (
    <Wrapper>
      <Input
        type={type}
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus={autoFocus}
      />
      <Button type='button' onClick={onClick}>
        {text}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 36px;
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0 0 4px var(--color-primary-light);

  :focus-within {
    border-color: var(--color-primary);
  }
`;

const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 100%;
  margin-right: 16px;
  border: none;
  border-radius: 8px;

  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  font-size: var(--font-body1);
  font-weight: var(--font-bold);
  line-height: var(--font-body1);
  border-radius: 8px;
  background-color: var(--color-primary);
  color: var(--font-on-primary);
`;

export default InputWithButton;
