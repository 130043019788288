import { callApi } from '../services/api';

const availableCouponList = ({ page, limit }) => {
  const params = { page, limit };

  return callApi.get('/coupons', { params });
};

const myCouponList = ({ page, limit, status }) => {
  const params = { page, limit, status };

  return callApi.get('/member/coupons', { params });
};

const getCoupon = (data) => {
  return callApi.post('/member/coupons', data);
};

const exchangeCoupon = ({ serialNumber, id }) => {
  const params = { sn: serialNumber, couponId: id };

  return callApi.put('/member/coupons', params);
};

export { availableCouponList, myCouponList, getCoupon, exchangeCoupon };
