import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import Main from '../../components/Main';
import Header from '../../components/Header';
import IconButton from '../../components/button/IconButton';
import Heading2 from '../../components/heading/Heading2';
import Section from '../../components/section/Section';
import Button from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import FormItem from '../../components/FormItem';
import Input from '../../components/Input';
import Label from '../../components/Label';
import { modifyProfiles } from '../../module/api';
import { showErrorMessage, showSuccessMessage } from '../../module/message';

const ChangePassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const checkPassword = () => {
    if (!password.oldPassword.trim()) {
      showErrorMessage({ message: 'Please fill up old Password' });
      return false;
    }
    if (!password.newPassword.trim()) {
      showErrorMessage({ message: 'Please fill up new password' });
      return false;
    }
    if (!password.confirmPassword.trim()) {
      showErrorMessage({ message: 'Please fill up confirm password' });
      return false;
    }
    if (password.newPassword.trim() !== password.confirmPassword.trim()) {
      showErrorMessage({
        message: 'New password and confirm password does not match',
      });
      return false;
    }
    return true;
  };

  const handleUpdateData = () => {
    if (!checkPassword()) return;
    const requestData = {
      oldPassword: password.oldPassword.trim(),
      password: password.newPassword.trim(),
    };
    modifyProfiles(requestData)
      .then((response) => {
        if (response) {
          showSuccessMessage({ message: 'success' });
          navigate(-1);
        }
      })
      .catch((error) => {
        showErrorMessage({ message: error.message });
      });
  };

  const handleChangeValue = (valse, key) => {
    setPassword((previous) => {
      const cpData = { ...previous };
      cpData[key] = valse;
      return cpData;
    });
  };

  return (
    <Main>
      <Header>
        <div className='left'>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaArrowLeft />
          </IconButton>
        </div>
        <Heading2>Change Password</Heading2>
      </Header>

      <Section>
        <FormItem>
          <Label>Old Password</Label>
          <Input
            value={password.oldPassword}
            onChange={(event) => {
              handleChangeValue(event.target.value, 'oldPassword');
            }}
          />
        </FormItem>
        <FormItem>
          <Label>New Password</Label>
          <Input
            value={password.newPassword}
            onChange={(event) => {
              handleChangeValue(event.target.value, 'newPassword');
            }}
          />
        </FormItem>
        <FormItem>
          <Label>Confirm Password</Label>
          <Input
            value={password.confirmPassword}
            onChange={(event) => {
              handleChangeValue(event.target.value, 'confirmPassword');
            }}
          />
        </FormItem>

        <ButtonGroup fixed>
          <Button onClick={handleUpdateData}>Update</Button>
        </ButtonGroup>
      </Section>
    </Main>
  );
};

export default ChangePassword;
