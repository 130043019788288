import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  login,
  register,
  sendVerificationCode,
  getIPAddressLocation,
} from '../../module/api';
import { showSuccessMessage, showErrorMessage } from '../../module/message';
import useQuery from '../../utils/useQuery';
import AuthenticateContext from '../../provider/context/authenticate.context';
import Layout from '../../components/layout/Layout';
import Heading1 from '../../components/Heading1';
import FormItem from '../../components/FormItem';
import Label from '../../components/Label';
import Input from '../../components/Input';
import { Select } from '../../components/select';
import InputWithButton from '../../components/InputWithButton';
import Button from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import countryList from '../../assets/json/country.json';

const Register = () => {
  const navigate = useNavigate();
  const serialNumber = useQuery().get('sn') || '';
  const { updateToken } = useContext(AuthenticateContext);
  const [step, setStep] = useState('verify');
  const [countryCode, setCountryCode] = useState('');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    login({
      phone: `${countryList[countryCode].dialCode}${phone.trim()}`,
      password,
    })
      .then((response) => {
        if (response) {
          const { token } = response.data.data;
          updateToken(token);

          navigate(`/coupon?sn=${serialNumber}`);
        }
      })
      .catch((error) => {
        showErrorMessage({ message: error.message });
      });
  };

  const handleRegister = () => {
    if (!countryCode || !phone.trim()) {
      showErrorMessage({ message: 'Phone can not be empty' });
      return false;
    }

    if (!password.trim()) {
      showErrorMessage({ message: 'Password can not be empty' });
      return false;
    }

    register({
      phone: `${countryList[countryCode].dialCode}${phone.trim()}`,
      code,
      password,
    })
      .then((response) => {
        if (response) {
          handleLogin();
        }
      })
      .catch((error) => {
        showErrorMessage({ message: error.message });
      });
  };

  useEffect(() => {
    getIPAddressLocation()
      .then((response) => {
        const { data } = response;
        if (data.country_code) {
          const found = Object.values(countryList).find((country) => {
            return data.country_code.toLowerCase() === country.iso2;
          });

          setCountryCode(found?.name);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <Layout>
      <Heading1 className='margin-top-xl margin-bottom-xxl'>Register</Heading1>

      {step === 'verify' && (
        <VerifyForm
          setStep={setStep}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          phone={phone}
          setPhone={setPhone}
          code={code}
          setCode={setCode}
        />
      )}

      {step === 'password' && (
        <PasswordForm
          password={password}
          setPassword={setPassword}
          handleRegister={handleRegister}
        />
      )}
    </Layout>
  );
};

const VerifyForm = ({
  setStep,
  countryCode,
  setCountryCode,
  phone,
  setPhone,
  code,
  setCode,
}) => {
  const navigate = useNavigate();
  const [isSended, setIsSended] = useState(false);

  const handleSendVerificationCode = () => {
    if (!countryCode || !phone.trim()) {
      showErrorMessage({ message: 'Phone can not be empty' });
      return false;
    }

    sendVerificationCode({
      phone: `${countryList[countryCode].dialCode}${phone.trim()}`,
      codeType: 0,
    })
      .then((response) => {
        if (response) {
          setIsSended(true);
          showSuccessMessage({
            message: 'Verification code has been sent.',
          });
        }
      })
      .catch((error) => {
        showErrorMessage({ message: error.message });
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!code.trim()) {
      showErrorMessage({ message: 'Code can not be empty' });
      return false;
    }

    setStep('password');
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormItem>
        <Label htmlFor='phone'>Enter your phone</Label>
        <Select
          fullWidth
          allowSearch={true}
          options={Object.entries(countryList).map(([key, data]) => {
            return { id: key, text: `${data.name} ${data.dialCode}` };
          })}
          selected={countryCode}
          onSelect={setCountryCode}
        />
        <InputWithButton
          type='number'
          pattern='\d*'
          id='phone'
          value={phone}
          onChange={(event) => {
            setPhone(event.target.value);
          }}
          onClick={handleSendVerificationCode}
          text={isSended ? 'Resend' : 'Get Code'}
          autoFocus
        />
      </FormItem>

      <FormItem>
        <Label htmlFor='verificationCode'>Verification code</Label>
        <Input
          type='text'
          id='verificationCode'
          value={code}
          onChange={(event) => {
            setCode(event.target.value);
          }}
        />
      </FormItem>

      <ButtonGroup className='margin-top-xxl'>
        <Button
          secondary
          type='button'
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </Button>
        <Button type='submit'>Next</Button>
      </ButtonGroup>
    </form>
  );
};

const PasswordForm = ({ password, setPassword, handleRegister }) => {
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    const trimedPassword = password.trim();
    const trimedPasswordConfirmation = confirmPassword.trim();

    if (!trimedPassword) {
      showErrorMessage({ message: 'Password can not be empty' });
      return false;
    }

    if (!trimedPasswordConfirmation) {
      showErrorMessage({ message: 'Confirm password can not be empty' });
      return false;
    }

    if (trimedPassword !== trimedPasswordConfirmation) {
      showErrorMessage({
        message: 'Password and confirm password does not match',
      });
      return;
    }

    handleRegister();
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormItem>
        <Label htmlFor='password'>Password</Label>
        <Input
          type='password'
          id='password'
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          autoComplete='new-password'
          autoFocus
        />
      </FormItem>

      <FormItem>
        <Label htmlFor='confirmPassword'>Confirm Password</Label>
        <Input
          type='password'
          id='confirmPassword'
          value={confirmPassword}
          onChange={(event) => {
            setConfirmPassword(event.target.value);
          }}
          autoComplete='new-password'
        />
      </FormItem>

      <ButtonGroup className='margin-top-xxl'>
        <Button
          secondary
          type='button'
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </Button>
        <Button type='submit'>Confirm</Button>
      </ButtonGroup>
    </form>
  );
};

export default Register;
