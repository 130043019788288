import { useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { ImProfile } from 'react-icons/im';
import { FaSignOutAlt } from 'react-icons/fa';
import { showSuccessMessage, showErrorMessage } from '../../module/message';
import { redeemCode } from '../../module/api';
import {
  availableCouponList,
  myCouponList,
  getCoupon,
  exchangeCoupon,
} from '../../module/coupon';
import useQuery from '../../utils/useQuery';
import AuthenticateContext from '../../provider/context/authenticate.context';
import Main from '../../components/Main';
import Header from '../../components/Header';
import Heading2 from '../../components/heading/Heading2';
import Section from '../../components/section/Section';
import Tab from '../../components/tab';
import IconButton from '../../components/button/IconButton';
import InputWithButton from '../../components/InputWithButton';
import Ticket from '../../components/coupon/Ticket';
import Dropdown, {
  DropdownItem,
  DropdownButtonOption,
  DropdownLinkOption,
} from '../../components/dropdown';

const Coupon = () => {
  const navigate = useNavigate();
  const { removeAuthenticate } = useContext(AuthenticateContext);
  const [selectedTab, setSelectedTab] = useState('myCoupon');
  const serialNumber = useQuery().get('sn') || '';
  const [isFetchingMyCoupon, setIsFetchingMyCoupon] = useState(false);
  const [myCouponData, setMyCouponData] = useState([]);
  const [myCouponListParams, setMyCouponListParams] = useState({
    page: 0,
    limit: 10,
    lastPage: 1,
  });
  const [isFetchingAvailableCoupon, setIsFetchingAvailableCoupon] =
    useState(false);
  const [availableCouponData, setAvailableCouponData] = useState([]);
  const [availableCouponListParams, setAvailableCouponListParams] = useState({
    page: 0,
    limit: 10,
    lastPage: 1,
  });
  const [newMyCoupon, setNewMyCoupon] = useState([]);

  const handleTabChange = useCallback((name) => {
    setSelectedTab(name);
  }, []);

  const handleLogout = () => {
    removeAuthenticate();
    navigate(`/login?sn=${serialNumber}`);
  };

  const handleGetAvailableCouponList = useCallback(() => {
    if (availableCouponListParams.page >= availableCouponListParams.lastPage)
      return;
    if (isFetchingAvailableCoupon) return;

    setIsFetchingAvailableCoupon(true);
    const page = availableCouponListParams.page + 1;
    availableCouponList({
      page: page,
      limit: availableCouponListParams.limit,
    })
      .then((response) => {
        if (response) {
          const { total, list } = response.data.data;

          setAvailableCouponListParams((previous) => {
            return {
              ...previous,
              page: page,
              lastPage: Math.ceil(total / availableCouponListParams.limit) || 1,
            };
          });

          if (page === 1) {
            setAvailableCouponData(
              list.map((item) => {
                return { ...item, status: 'Get' };
              }),
            );
          } else {
            setAvailableCouponData((previous) => {
              return [...previous].concat(
                list.map((item) => {
                  return { ...item, status: 'Get' };
                }),
              );
            });
          }

          setIsFetchingAvailableCoupon(false);
        }
      })
      .catch((error) => {
        showErrorMessage({ message: error.message });
      });
  }, [
    availableCouponListParams.page,
    availableCouponListParams.limit,
    availableCouponListParams.lastPage,
    isFetchingAvailableCoupon,
  ]);

  const handleGetMyCouponList = useCallback(() => {
    if (myCouponListParams.page >= myCouponListParams.lastPage) return;
    if (isFetchingMyCoupon) return;

    setIsFetchingMyCoupon(true);
    const page = myCouponListParams.page + 1;
    myCouponList({
      page: page,
      limit: myCouponListParams.limit,
      status: 0,
    })
      .then((response) => {
        if (response) {
          const { total, list } = response.data.data;

          setMyCouponListParams((previous) => {
            return {
              ...previous,
              page: page,
              lastPage: Math.ceil(total / myCouponListParams.limit) || 1,
            };
          });

          if (page === 1) {
            setMyCouponData(
              list.map((item) => {
                return { ...item, status: 'Use' };
              }),
            );
          } else {
            setMyCouponData((previous) => {
              return [...previous].concat(
                list.map((item) => {
                  return { ...item, status: 'Use' };
                }),
              );
            });
          }

          setIsFetchingMyCoupon(false);
        }
      })
      .catch((error) => {
        showErrorMessage({ message: error.message });
      });
  }, [
    myCouponListParams.page,
    myCouponListParams.limit,
    myCouponListParams.lastPage,
    isFetchingMyCoupon,
  ]);

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 50) {
      if (selectedTab === 'myCoupon') {
        handleGetMyCouponList();
        return;
      }

      if (selectedTab === 'available') {
        handleGetAvailableCouponList();
        return;
      }
    }
  };

  const handleGetCoupon = useCallback(async (id) => {
    try {
      const response = await getCoupon({ couponId: id });
      if (response) {
        // reset my coupon list
        setMyCouponListParams((previous) => {
          return { ...previous, page: 0 };
        });
        return true;
      }
    } catch (error) {
      showErrorMessage({ message: error.message });
    }

    return false;
  }, []);

  const handleUseCoupon = useCallback(
    (id) => {
      exchangeCoupon({ serialNumber, id })
        .then((response) => {
          if (response) {
            // reset my coupon list
            setMyCouponListParams((previous) => {
              return { ...previous, page: 0 };
            });

            // reset available coupon list
            setAvailableCouponListParams((previous) => {
              return { ...previous, page: 0 };
            });
          }
        })
        .catch((error) => {
          showErrorMessage({ message: error.message });
        });
    },
    [serialNumber],
  );

  // first get available coupon list
  useEffect(() => {
    if (availableCouponListParams.page === 0) {
      handleGetAvailableCouponList();
    }
  }, [availableCouponListParams.page, handleGetAvailableCouponList]);

  // first get my coupon list
  useEffect(() => {
    if (myCouponListParams.page === 0) {
      handleGetMyCouponList();
    }
  }, [myCouponListParams.page, handleGetMyCouponList]);

  return (
    <Main onScroll={handleScroll}>
      <Header>
        <Heading2>Coupon</Heading2>
        <div className='right'>
          <Dropdown fixed>
            <Dropdown.Toggle>
              <IconButton aria-haspopup='true' aria-labelledby='menu'>
                <BsThreeDotsVertical />
              </IconButton>
            </Dropdown.Toggle>
            <Dropdown.Content>
              <DropdownItem>
                <DropdownLinkOption to={`/profile?sn=${serialNumber}`}>
                  <ImProfile />
                  Profile
                </DropdownLinkOption>
              </DropdownItem>
              <DropdownItem onClick={handleLogout}>
                <DropdownButtonOption>
                  <FaSignOutAlt />
                  Log out
                </DropdownButtonOption>
              </DropdownItem>
            </Dropdown.Content>
          </Dropdown>
        </div>
      </Header>

      <Section noPadding>
        <Tab selected={selectedTab} onChange={handleTabChange}>
          <Tab.List aria-labelledby='tab' fixed>
            <Tab.Tab name='myCoupon' id='myCoupon'>
              My Coupon
            </Tab.Tab>
            <Tab.Tab name='available' id='available'>
              Available
            </Tab.Tab>
            <Tab.Tab name='discountCode' id='discountCode'>
              Discount Code
            </Tab.Tab>
          </Tab.List>

          <Tab.Panel name='myCoupon' aria-labelledby='myCoupon'>
            <MyCoupon
              myCouponData={myCouponData}
              handleUseCoupon={handleUseCoupon}
              newMyCoupon={newMyCoupon}
            />
          </Tab.Panel>
          <Tab.Panel name='available' aria-labelledby='available'>
            <Available
              availableCouponData={availableCouponData}
              setAvailableCouponData={setAvailableCouponData}
              handleGetCoupon={handleGetCoupon}
              handleUseCoupon={handleUseCoupon}
              setNewMyCoupon={setNewMyCoupon}
            />
          </Tab.Panel>
          <Tab.Panel name='discountCode' aria-labelledby='discountCode'>
            <DiscountCode serialNumber={serialNumber} />
          </Tab.Panel>
        </Tab>
      </Section>
    </Main>
  );
};

const MyCoupon = ({ myCouponData, handleUseCoupon, newMyCoupon }) => {
  return myCouponData.map((item) => {
    return (
      <Ticket
        haveNewTag={newMyCoupon.includes(item.id)}
        key={item.id}
        title={item.title}
        subtitle={`Expiry Date: ${item.validEndAt} (${item.timezoneName} ${item.timezoneOffset})`}
        text={item.remarks}
        onClick={() => {
          handleUseCoupon(item.id);
        }}
        status={item.status}
      />
    );
  });
};

const Available = ({
  availableCouponData,
  setAvailableCouponData,
  handleGetCoupon,
  handleUseCoupon,
  setNewMyCoupon,
}) => {
  const handleOnClick = async (id, index) => {
    if (availableCouponData[index].status === 'Get') {
      const result = await handleGetCoupon(id);
      if (result) {
        setNewMyCoupon((preData) => {
          return [...preData, id];
        });

        setAvailableCouponData((preData) => {
          const newData = [...preData];
          newData[index].status = 'Use';

          return newData;
        });
      }

      return;
    }

    if (availableCouponData[index].status === 'Use') {
      handleUseCoupon(id);
      return;
    }
  };

  return availableCouponData.map((item, index) => {
    return (
      <Ticket
        key={item.id}
        title={item.title}
        subtitle={`Expiry Date: ${item.validEndDate} (${item.timezoneName} ${item.timezoneOffset})`}
        text={item.remarks}
        onClick={() => {
          handleOnClick(item.id, index);
        }}
        status={item.status}
      />
    );
  });
};

const DiscountCode = ({ serialNumber }) => {
  const [code, setCode] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!serialNumber.trim()) {
      showErrorMessage({ message: 'Serial number can not be empty' });
      return false;
    }

    if (!code.trim()) {
      showErrorMessage({ message: 'Code can not be empty' });
      return false;
    }

    redeemCode({ serialNumber, code })
      .then((response) => {
        if (response) {
          showSuccessMessage({
            message:
              'Successful redeem. Please proceed follow-up operation on vending machine.',
          });
        }
      })
      .catch((error) => {
        showErrorMessage({ message: error.message });
      });
  };

  return (
    <InputWithButton
      type='text'
      id='promoCode'
      value={code}
      placeholder='Enter your discount code'
      onChange={(event) => {
        setCode(event.target.value);
      }}
      onClick={handleSubmit}
      text='Redeem'
      autoFocus
    />
  );
};

export default Coupon;
