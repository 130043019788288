import styled from 'styled-components';

const FormItem = styled.div`
  margin-bottom: 16px;

  *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export default FormItem;
