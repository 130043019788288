import styled from 'styled-components';

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: min(100%, 576px);
  height: 64px;
  z-index: 40;
  background: var(--color-primary);
  color: var(--font-on-primary);

  .left {
    position: absolute;
    left: 32px;
  }

  .right {
    position: absolute;
    right: 32px;
  }
`;

export default Header;
