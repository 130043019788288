import styled from 'styled-components';

const Main = styled.main`
  height: 100%;
  overflow: auto;
  padding-top: 64px;
  padding-bottom: 36px;
`;

export default Main;
