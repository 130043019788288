import { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { login, getIPAddressLocation } from '../../module/api';
import { showErrorMessage } from '../../module/message';
import useQuery from '../../utils/useQuery';
import AuthenticateContext from '../../provider/context/authenticate.context';
import Layout from '../../components/layout/Layout';
import Heading1 from '../../components/Heading1';
import FormItem from '../../components/FormItem';
import Label from '../../components/Label';
import Input from '../../components/Input';
import { Select } from '../../components/select';
import Button from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import countryList from '../../assets/json/country.json';

const Login = () => {
  const navigate = useNavigate();
  const serialNumber = useQuery().get('sn') || '';
  const { updateToken } = useContext(AuthenticateContext);
  const [countryCode, setCountryCode] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!countryCode || !phone.trim()) {
      showErrorMessage({ message: 'Phone can not be empty' });
      return false;
    }

    if (!password.trim()) {
      showErrorMessage({ message: 'Password can not be empty' });
      return false;
    }

    login({
      phone: `${countryList[countryCode].dialCode}${phone.trim()}`,
      password,
    })
      .then((response) => {
        if (response) {
          const { token } = response.data.data;
          updateToken(token);

          navigate(`/coupon?sn=${serialNumber}`);
        }
      })
      .catch((error) => {
        showErrorMessage({ message: error.message });
      });
  };

  useEffect(() => {
    getIPAddressLocation()
      .then((response) => {
        const { data } = response;
        if (data.country_code) {
          const found = Object.values(countryList).find((country) => {
            return data.country_code.toLowerCase() === country.iso2;
          });

          setCountryCode(found?.name);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <Layout>
      <Heading1 className='margin-top-xl margin-bottom-xxl'>Login</Heading1>

      <form onSubmit={handleSubmit}>
        <FormItem>
          <Label htmlFor='phone'>Phone</Label>
          <Select
            fullWidth
            allowSearch={true}
            options={Object.entries(countryList).map(([key, data]) => {
              return { id: key, text: `${data.name} ${data.dialCode}` };
            })}
            selected={countryCode}
            onSelect={setCountryCode}
          />
          <Input
            type='number'
            pattern='\d*'
            id='phone'
            value={phone}
            onChange={(event) => {
              setPhone(event.target.value);
            }}
            autoFocus
          />
        </FormItem>

        <FormItem>
          <Label htmlFor='password'>Password</Label>
          <Input
            type='password'
            id='password'
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            autoComplete='current-password'
          />
          <Link
            to={`/reset-password?sn=${serialNumber}`}
            style={{
              color: 'var(--color-black)',
              textDecoration: 'underline',
              float: 'right',
            }}
          >
            Forget Password?
          </Link>
          <div className='clearFix'></div>
        </FormItem>

        <ButtonGroup className='margin-top-xxl'>
          <Button
            secondary
            type='button'
            onClick={() => {
              navigate(`/?sn=${serialNumber}`);
            }}
          >
            Cancel
          </Button>
          <Button type='submit'>Login</Button>
        </ButtonGroup>
      </form>

      <section
        style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '60px' }}
      >
        <p>
          Don't have an account?&nbsp;
          <Link
            to={`/register?sn=${serialNumber}`}
            style={{
              color: 'var(--color-primary)',
              textDecoration: 'underline',
            }}
          >
            Register!
          </Link>
        </p>
      </section>
    </Layout>
  );
};

export default Login;
