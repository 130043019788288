import styled from 'styled-components';
import ImageFluid from '../ImageFluid';
// import logo from '../../assets/images/Logo.png';
import logo from '../../assets/images/logo-castles.png';

const Logo = () => {
  return (
    <Figure>
      <ImageFluid src={logo} alt='logo' />
    </Figure>
  );
};

const Figure = styled.figure`
  max-width: 320px;
`;

export default Logo;
