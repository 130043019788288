import styled from 'styled-components';
import Button from '../Button';

const Ticket = ({
  haveNewTag = false,
  title,
  subtitle,
  text,
  onClick,
  status,
}) => {
  return (
    <Wrapper>
      <Mask left />
      <Mask right />
      {haveNewTag && <Tag>New</Tag>}

      <Content>
        <DescriptionBlock>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
          <Text>{text}</Text>
        </DescriptionBlock>
        <ButtonBlock>
          <Button onClick={onClick}>{status}</Button>
        </ButtonBlock>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  background: var(--color-white);
  box-shadow: 0px 0px 4px var(--color-primary-light);
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 10px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    box-shadow: 0px 0px 4px var(--color-primary-light);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--color-white);
  }

  &::before {
    left: -20px;
  }

  &::after {
    right: -20px;
  }
`;

const Mask = styled.div`
  position: absolute;
  height: 100%;
  width: 24px;
  background: var(--color-white);
  z-index: 1;

  ${({ left }) =>
    left &&
    `
    left: -26px;
  `}

  ${({ right }) =>
    right &&
    `
    right: -26px;
  `}
`;

const Tag = styled.span`
  background: #707070;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 9px;
  margin: 20px 15px;
  position: absolute;
  transform: rotate(325deg);
  top: -22px;
  left: -32px;
  z-index: 2;
`;

const Content = styled.div`
  display: flex;
  padding: 12px 20px;
  width: 100%;
  min-height: 90px;
`;

const DescriptionBlock = styled.div`
  flex: 1;
  padding-right: 12px;
  border-right: 1px dashed var(--font-on-background);
`;

const ButtonBlock = styled.div`
  padding-left: 12px;
  align-self: center;
  min-width: 80px;
`;

const Title = styled.p`
  font-size: var(--font-body1);
  color: var(--font-on-background);
  line-height: 1.2;
`;

const SubTitle = styled.p`
  font-size: var(--font-caption);
  color: var(--font-on-background);
  margin-top: 4px;
  line-height: 18px;
`;

const Text = styled.p`
  color: #707070;
  font-size: var(--font-caption);
  margin-top: 12px;
`;

export default Ticket;
