import styled from 'styled-components';

const Heading2 = styled.h2`
  font-size: var(--font-h2);
  font-weight: var(--font-bold);
  text-align: center;
  word-break: break-word;
  white-space: pre-wrap;
`;

export default Heading2;
