import styled from 'styled-components';

const IconButton = styled.div.attrs(() => ({
  role: 'button',
}))`
  width: 32px;
  height: 32px;
  transition: 0.3s;
  position: relative;
  cursor: pointer;
  margin: auto;

  > svg {
    font-size: 1.5rem;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default IconButton;
