import styled from 'styled-components';

const TabList = ({ children, ...props }) => {
  return (
    <Container role='tablist' {...props}>
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(255, 77, 79, 0.66);

  ${({ fixed }) =>
    fixed &&
    `
    position: fixed;
    top: 64px;
    width: min(100%, 576px);
    z-index: 20;
  `}
`;

export default TabList;
