import styled from 'styled-components';
import Logo from './Logo';
import Wavy from './Wavy';

const Layout = ({ children }) => {
  return (
    <>
      <Header>
        <Logo />
      </Header>
      <Wavy />
      <Main>{children}</Main>
    </>
  );
};

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: var(--color-primary);
`;

const Main = styled.main`
  padding: 0 40px;
`;

export default Layout;
