const colorStyle = {
  colorWhite: '#ffffff',
  colorBlack: '#262626',
  colorPrimary: '#F5222D',
  colorPrimaryLight: '#FF4D4F',
};

const fontStyle = {
  fontH1: '2rem',
  fontH2: '1.5rem',
  fontH3: '1.25rem',
  fontBody1: '1rem',
  fontBody2: '0.875rem',
  fontCaption: '0.875rem',
  fontFamily: 'Segoe UI',
  fontWeightNormal: '400',
  fontWeightBold: 'bold',
  fontOnPrimary: colorStyle.colorWhite,
  fontOnBackground: colorStyle.colorBlack,
};

const colaTheme = {
  ...fontStyle,
  ...colorStyle,
};

export default colaTheme;
