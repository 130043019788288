import { useEffect, useMemo, useState } from 'react';
import { FaPen } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import useQuery from '../../utils/useQuery';
import Main from '../../components/Main';
import Header from '../../components/Header';
import IconButton from '../../components/button/IconButton';
import Heading2 from '../../components/heading/Heading2';
import Section from '../../components/section/Section';
import Button from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import FormItem from '../../components/FormItem';
import Input from '../../components/Input';
import Label from '../../components/Label';
import { Select } from '../../components/select';
import { getCurrentUserInfo, modifyProfiles } from '../../module/api';
import { showErrorMessage, showSuccessMessage } from '../../module/message';

const GENDER = [
  { text: 'Male', id: 0 },
  { text: 'Female', id: 1 },
];

const Profile = () => {
  const navigate = useNavigate();
  const serialNumber = useQuery().get('sn') || '';
  const [pageMode, setPageMode] = useState('view');
  const [profileData, setProfileData] = useState({
    nickname: 'oOMayOo',
    email: 'may@gmail.com',
    gender: 0,
  });

  const changeMode = (mode) => {
    setPageMode(mode);
  };

  const handleChangeValue = (value, key) => {
    setProfileData((previous) => {
      const cpData = { ...previous };
      cpData[key] = value;
      return cpData;
    });
  };

  const handleUpdateData = () => {
    const requestData = {
      nickname: profileData.nickname,
      gender: profileData.gender,
    };

    modifyProfiles(requestData)
      .then((response) => {
        if (response) {
          showSuccessMessage({ message: 'success' });
          changeMode('view');
        }
      })
      .catch((error) => {
        showErrorMessage({ message: error.message });
      });
  };

  const title = useMemo(() => {
    return pageMode === 'view' ? 'Profile' : 'Modify Profile';
  }, [pageMode]);

  useEffect(() => {
    getCurrentUserInfo()
      .then((response) => {
        if (response) {
          const { data } = response.data;
          setProfileData(data);
        }
      })
      .catch((error) => {
        showErrorMessage({ message: error.message });
      });
  }, []);

  return (
    <Main>
      <Header>
        <div className='left'>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaArrowLeft />
          </IconButton>
        </div>
        <Heading2>{title}</Heading2>
      </Header>

      <Section>
        <FormItem>
          <Label>Name</Label>
          <Input
            value={profileData.nickname}
            onChange={(e) => handleChangeValue(e.target.value, 'nickname')}
            disabled={pageMode === 'view'}
          />
        </FormItem>
        <FormItem>
          <Label>Email</Label>
          <Input
            value={profileData.email}
            onChange={(e) => handleChangeValue(e.target.value, 'email')}
            disabled={pageMode === 'view'}
          />
        </FormItem>
        <FormItem>
          <Label>Gender</Label>
          <Select
            fullWidth
            allowSearch={false}
            options={GENDER}
            selected={profileData.gender}
            onSelect={(id) => handleChangeValue(id, 'gender')}
            disabled={pageMode === 'view'}
          />
        </FormItem>
        {pageMode === 'modify' && (
          <FormItem>
            <Label>Password</Label>

            <Link to={`/change-password?sn=${serialNumber}`}>
              <Button secondary>
                <FaPen />
                &nbsp; Change Password
              </Button>
            </Link>
          </FormItem>
        )}

        <ButtonGroup fixed>
          {pageMode === 'view' ? (
            <Button onClick={() => changeMode('modify')}>Modify</Button>
          ) : (
            <Button onClick={handleUpdateData}>Update</Button>
          )}
        </ButtonGroup>
      </Section>
    </Main>
  );
};

export default Profile;
