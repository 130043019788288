import styled from 'styled-components';

const ButtonGroup = styled.div`
  display: flex;
  background: var(--color-white);

  > button:not(:first-child) {
    margin-left: 16px;
  }

  ${({ fixed }) =>
    fixed &&
    `
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: min(100%, 576px);
    padding: 24px;
    z-index: 20;
  `}
`;

export default ButtonGroup;
