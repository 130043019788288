import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

const CustomToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    margin: 8px;
    border-radius: 8px;
    min-height: 48px;
  }

  .Toastify__toast-body {
    font-weight: bold;
  }

  .Toastify__close-button {
    align-self: center;
  }
`;

export default CustomToastContainer;
