import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendVerificationCode } from '../../module/api';
import { showSuccessMessage, showErrorMessage } from '../../module/message';
import Layout from '../../components/layout/Layout';
import Heading1 from '../../components/Heading1';
import FormItem from '../../components/FormItem';
import Label from '../../components/Label';
import Input from '../../components/Input';
import InputWithButton from '../../components/InputWithButton';
import Button from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';

const ResetPassword = () => {
  const [step, setStep] = useState('verify');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');

  return (
    <Layout>
      <Heading1 className='margin-top-xl margin-bottom-xxl'>
        Reset Password
      </Heading1>

      {step === 'verify' && (
        <VerifyForm
          setStep={setStep}
          phone={phone}
          setPhone={setPhone}
          code={code}
          setCode={setCode}
        />
      )}

      {step === 'password' && (
        <PasswordForm password={password} setPassword={setPassword} />
      )}
    </Layout>
  );
};

const VerifyForm = ({ setStep, phone, setPhone, code, setCode }) => {
  const navigate = useNavigate();
  const [isSended, setIsSended] = useState(false);

  const handleSendVerificationCode = () => {
    if (!phone.trim()) {
      showErrorMessage({ message: 'Phone can not be empty' });
      return false;
    }

    sendVerificationCode({ phone, codeType: 0 })
      .then((response) => {
        if (response) {
          setIsSended(true);
          showSuccessMessage({
            message: 'Verification code has been sent.',
          });
        }
      })
      .catch((error) => {
        showErrorMessage({ message: error.message });
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!code.trim()) {
      showErrorMessage({ message: 'Code can not be empty' });
      return false;
    }

    setStep('password');
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormItem>
        <Label htmlFor='phone'>Enter your phone</Label>
        <InputWithButton
          type='text'
          id='phone'
          value={phone}
          onChange={(event) => {
            setPhone(event.target.value);
          }}
          onClick={handleSendVerificationCode}
          text={isSended ? 'Resend' : 'Get Code'}
          autoFocus
        />
      </FormItem>

      <FormItem>
        <Label htmlFor='verificationCode'>Verification code</Label>
        <Input
          type='text'
          id='verificationCode'
          value={code}
          onChange={(event) => {
            setCode(event.target.value);
          }}
        />
      </FormItem>

      <ButtonGroup className='margin-top-xxl'>
        <Button
          secondary
          type='button'
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </Button>
        <Button type='submit'>Next</Button>
      </ButtonGroup>
    </form>
  );
};

const PasswordForm = ({ password, setPassword }) => {
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    const trimedPassword = password.trim();
    const trimedPasswordConfirmation = confirmPassword.trim();

    if (!trimedPassword) {
      showErrorMessage({ message: 'Password can not be empty' });
      return false;
    }

    if (!trimedPasswordConfirmation) {
      showErrorMessage({ message: 'Confirm password can not be empty' });
      return false;
    }

    if (trimedPassword !== trimedPasswordConfirmation) {
      showErrorMessage({
        message: 'Password and confirm password does not match',
      });
      return;
    }

    // todo: reset password or register again?
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormItem>
        <Label htmlFor='password'>Password</Label>
        <Input
          type='password'
          id='password'
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          autoComplete='new-password'
          autoFocus
        />
      </FormItem>

      <FormItem>
        <Label htmlFor='confirmPassword'>Confirm Password</Label>
        <Input
          type='password'
          id='confirmPassword'
          value={confirmPassword}
          onChange={(event) => {
            setConfirmPassword(event.target.value);
          }}
          autoComplete='new-password'
        />
      </FormItem>

      <ButtonGroup className='margin-top-xxl'>
        <Button
          secondary
          type='button'
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </Button>
        <Button type='submit'>Confirm</Button>
      </ButtonGroup>
    </form>
  );
};

export default ResetPassword;
